import { ReactElement } from 'react';
import { TextField, useMediaQuery } from '@mui/material';
import { vcsPathValidator } from '../../../utils';

interface VcsPathInputProps {
    svnPath?: string;
    onChange: (svnPath?: string) => void;
}

export default function VcsPathInput({ svnPath = '', onChange }: VcsPathInputProps): ReactElement {
    const isMobile = useMediaQuery('(max-width: 760px)');
    const hasError = Boolean(svnPath && !vcsPathValidator(svnPath));
    return (
        <TextField
            id='vcs-path-input'
            required
            label='Version Control Path'
            variant='outlined'
            value={svnPath}
            onChange={(e) => onChange(e.target.value || '')}
            error={hasError}
            helperText={
                hasError && 'Example: svn@svn-master.tactile.build/ProjectName or git@github.com:<org>/<repo>.git'
            }
            style={{ minWidth: isMobile ? '0px' : '350px' }}
        />
    );
}
