// eslint-disable-next-line import/no-extraneous-dependencies
import { History } from 'history';

export function sizeBytesToHuman(size: number): string {
    const magnitude = Math.floor(Math.log(size) / Math.log(1024));
    const stringSize =
        Number((size / Math.pow(1024, magnitude)).toFixed(2)) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][magnitude];
    return stringSize;
}

// Regular Expressions
export const BuildDetailsRegExp = /\/builds\/\w+\/[0-9]+/;
export const BuildDetailsSingleArtifactRegExp = /\/builds\/\w+\/[0-9]+\/.+/;

export function vcsPathValidator(vcsPath: string): boolean {
    const svnPattern = /^\w+@(?!(\d{1,3}\.){3}\d{1,3})([a-zA-Z0-9.-]+)\/\w+$/gim; // svn@tactile.build/projectName, no ip ranges
    const gitPattern = /\.git$/gim;
    return svnPattern.test(vcsPath) || gitPattern.test(vcsPath);
}

export function isSemanticOrXBranch(branch: string): boolean {
    const SemanticOrXBranch = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(x|0|[1-9]\d*)$/g;
    return SemanticOrXBranch.test(branch);
}

export function projectNameValidator(name: string): boolean {
    const namePatter = /^[A-Z][a-z]+(?:[A-Z][a-z]+)*$/gm; // FlowerGarden
    return namePatter.test(name);
}

export function handleRedirectToPage(event: any, url: string, history: History) {
    const mainButtonPressed = event.button === 0;
    const auxiliaryButtonPressed = event.button === 1;
    if (event.metaKey || event.ctrlKey || auxiliaryButtonPressed) {
        window.open(url, '_blank');
    } else if (mainButtonPressed) {
        history.push(url);
    }
}

export const ONE_GIGABYTE = 1073741824;
export const ONE_TERA_BYTE = 1099511627776;
